<template>
  <div class="home">
    <div>
      <img src="../../../public/images/xqy/低功耗wifi小黄人/images/QW1球机--38版小黄人_01.png" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi小黄人/images/QW1球机--38版小黄人_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi小黄人/images/QW1球机--38版小黄人_03.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi小黄人/images/QW1球机--38版小黄人_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi小黄人/images/QW1球机--38版小黄人_05.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi小黄人/images/QW1球机--38版小黄人_06.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi小黄人/images/QW1球机--38版小黄人_07.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi小黄人/images/QW1球机--38版小黄人_08.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi小黄人/images/QW1球机--38版小黄人_09.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi小黄人/images/QW1球机--38版小黄人_10.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi小黄人/images/QW1球机--38版小黄人_11.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'
export default {
  name: "低功耗wifi小黄人",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>

</style>